import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReactMarkdown from "react-markdown";
import Button from "react-bootstrap/Button";

const OwnBoat = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("mdFiles/own-boat.md")
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);

  return (
    <div className="own-boat-content">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          {/* left box */}
          <Grid item xs={6}>
            <h1>Own Boat and Bespoke Tuition</h1>
            <ReactMarkdown children={content} />
            <Button href="power" size="lg">RYA Powerboating</Button>
            <Button href="motor" size="lg">RYA Motorboating</Button>
            <Button href="sail" size="lg">RYA Sailing</Button>
          </Grid>
          {/* right box */}
          <Grid item xs={6}>
            <img src="img/vilamoura_marina.jpeg" alt="Vilamoura Marina" />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default OwnBoat;
