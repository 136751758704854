import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReactMarkdown from "react-markdown";

const Tcs = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("mdFiles/tcs.md")
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);

  return (
    <div className="tcs-content">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <ReactMarkdown children={content} />
        </Grid>
      </Box>
    </div>
  );
};

export default Tcs;
