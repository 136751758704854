import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";

export default function Footer() {
  return (
    <MDBFooter className="bg-light text-center text-white">
      <MDBContainer className="p-4 pb-0">
        <section className="mb-4 text-left">
          {/* facebook */}
          <MDBBtn
            floating
            id="contact-btn"
            className="m-1"
            href="https://www.facebook.com/RYAVilamoura"
            role="button"
            size="14vw"
          >
            <MDBIcon fab icon="facebook-f" />
          </MDBBtn>

          {/* fb messenger */}
          <MDBBtn
            floating
            id="contact-btn"
            className="m-1"
            href="https://m.me/RYAVilamoura"
            role="button"
            size="14vw"
          >
            <MDBIcon fab icon="facebook-messenger" />
          </MDBBtn>

          {/* whatsapp */}
          <MDBBtn
            floating
            id="contact-btn"
            className="m-1"
            href="https://api.whatsapp.com/send?phone=%2B351911813666&fbclid=IwAR0Z3EslRYtRoW3-HbM7QCmSpfUpP0pAuxejqK9_Jez3oclvCZ4APoxbmOc"
            role="button"
            size="14vw"
          >
            <MDBIcon fab icon="whatsapp" />
          </MDBBtn>
          {/* email */}
          <MDBBtn
            floating
            id="contact-btn"
            className="m-1"
            href="mailto:info@portugalsailandpower.com"
            role="button"
            size="14vw"
          >
            <MDBIcon far icon="envelope" />
          </MDBBtn>
          {/* google maps */}
          <MDBBtn
            floating
            id="contact-btn"
            className="m-1"
            href="https://goo.gl/maps/vv7jdH7UHkFe7F7D6"
            role="button"
            size="14vw"
          >
            <MDBIcon fas icon="map-marker-alt" />
          </MDBBtn>
        </section>
      </MDBContainer>
      <div
        className="text-center p-3"
        id="credits-footer"
      >
        Created by:&nbsp;
        <a className="text-white" href="https://services.jms.rocks/">
            James Twose
        </a>
      </div>
    </MDBFooter>
  );
}
