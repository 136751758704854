import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
const Contact = () => {
  return (
    <div className="contact-content">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          {/* left box */}
          <Grid item xs={4}>
            <div className="contact-info">
              <h2>Contact</h2>
              <p>
                Our office is located on the first floor. Use the stairs next to the Akvavit restaurant to reach us.
              </p>
              <p>
                <i className="fas fa-map-marker-alt"></i> Edifício Vilamarina, Nº 77, 8125-403, Quarteira, Portugal
              </p>
              <p>
                <i className="fas fa-phone-alt"></i> +351 911 813 666
              </p>
              <p>
                <i className="fab fa-square-whatsapp"></i> +351 911 813 666
              </p>
              <p>
                <i className="fab fa-facebook"></i>
                <a href="https://www.facebook.com/RYAVilamoura">
                  &nbsp;Portugal Sail and Power
                </a>
              </p>
              <p>
                <i className="fas fa-envelope"></i>
                <a href="mailto:info@portugalsailandpower.com">
                  &nbsp;info@portugalsailandpower.com
                </a>
              </p>
            </div>
          </Grid>
          {/* right box */}
          <Grid item xs={8}>
            <iframe
              className="map"
              title="Portugal Sail and Power"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3183.1330120621383!2d-8.122244799999999!3d37.07813879999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1ab5f071717ad1%3A0x65f7d4c78d1f68ca!2sPortugal%20Sail%20and%20Power!5e0!3m2!1sen!2spt!4v1679766195650!5m2!1sen!2spt"
              // width="800"
              // height="550"
              // style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            {/* <img src="img/psap-office-front.jpeg" alt="office" /> */}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Contact;
