import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReactMarkdown from "react-markdown";

const Vouchers = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("mdFiles/vouchers.md")
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);

  return (
    <div className="vouchers-content">
      <Box sx={{ flexGrow: 2 }}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <h1>Gift Vouchers</h1>
            <ReactMarkdown children={content} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Vouchers;
