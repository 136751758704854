import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReactMarkdown from "react-markdown";
import Button from "react-bootstrap/Button";

const Power = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("mdFiles/motor/motor-overview.md")
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);

  return (
    <div className="motor-content">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          {/* left box */}
          <Grid item xs={6}>
            <h1>Motorboating</h1>
            <ReactMarkdown children={content} />
            <Button href="motorboat-helmsman" size="lg">RYA Helmsman</Button>
            <Button href="motorboat-day-skipper" size="lg">RYA Day Skipper</Button>
            <Button href="motorboat-coastal-skipper" size="lg">RYA Coastal Skipper</Button>
            <Button href="motorboat-yachtmaster" size="lg">RYA Yachtmaster</Button>
          </Grid>
          {/* right box */}
          <Grid item xs={6}>
            <img src="img/RYA-motor-overview.jpg" alt="RYA motor boat" />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Power;
