import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReactMarkdown from "react-markdown";
import { Button } from "react-bootstrap";

const Shorebased = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("mdFiles/shorebased/shorebased-overview.md")
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);

  return (
    <div className="shorebased-content">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          {/* left box */}
          <Grid item xs={6}>
            <h1>Shorebased</h1>
            <ReactMarkdown children={content} />
            {/* Navigation */}
            <Button href="shorebased-navigation" size="lg">RYA Navigation</Button>
            <Button href="shorebased-day-skipper-theory" size="lg">RYA Day Skipper Theory</Button>
            <Button href="shorebased-coastal-skipper-theory" size="lg">RYA Coastal Skipper Theory</Button>
            <Button href="shorebased-yachtmaster-theory" size="lg">RYA Yachtmaster Theory</Button>
            {/* Specialist */}
            <Button href="shorebased-basic-sea-survival" size="lg">RYA Basic Sea Survival</Button>
            <Button href="shorebased-diesel-engine" size="lg">RYA Diesel Engine</Button>
            <Button href="shorebased-marine-radio-short-range" size="lg">RYA Marine Radio Short Range</Button>
            <Button href="shorebased-radar" size="lg">RYA Radar</Button>
            <Button href="shorebased-first-aid" size="lg">RYA First Aid</Button>
            <Button href="shorebased-professional-practices" size="lg">RYA Professional Practices & Responsibilities</Button>

          </Grid>
          {/* right box */}
          <Grid item xs={6}>
            <img src="img/RYA-shorebased-overview.jpg" alt="RYA shorebased boat" />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Shorebased;
