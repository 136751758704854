import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReactMarkdown from "react-markdown";

const About = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("mdFiles/about.md")
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);

  return (
    <div className="about-content">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          {/* left box */}
          <Grid item xs={6}>
            <h1>About</h1>
            <ReactMarkdown children={content} />
          </Grid>
          {/* right box */}
          <Grid item xs={6}>
            <div class="image-stack">
              <div className="image-stack__item image-stack__item--top">
                <img src="img/about-dave-sail.jpg" alt="Dave Masters Sail" />
              </div>
              <div className="image-stack__item image-stack__item--bottom">
                <img src="img/about-dave-power.jpg" alt="Dave Masters Power" />
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default About;
