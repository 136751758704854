import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReactMarkdown from "react-markdown";
import Button from "react-bootstrap/Button";

const Sail = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("mdFiles/sail/sail-overview.md")
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);

  return (
    <div className="sail-content">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          {/* left box */}
          <Grid item xs={6}>
            <h1>Sailing</h1>
            <ReactMarkdown children={content} />
            {/* <Button href="sailboat-start-yachting" size="lg">RYA Start Yachting</Button>
            <Button href="sailboat-competent-crew" size="lg">RYA Competent Crew</Button>
            <Button href="sailboat-day-skipper" size="lg">RYA Day Skipper</Button>
            <Button href="sailboat-coastal-skipper" size="lg">RYA Coastal Skipper</Button>
            <Button href="sailboat-yachtmaster" size="lg">RYA Yachtmaster</Button> */}
          </Grid>
          {/* right box */}
          <Grid item xs={6}>
            <img src="img/RYA-sail-overview.jpg" alt="RYA sail boat" />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Sail;
