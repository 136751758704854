import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import gfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import Button from "react-bootstrap/Button";

const SailBoatCharter = () => {
  const [content, setContent] = useState("");
  useEffect(() => {
    fetch("mdFiles/boat-charter/sailboat-charter.md")
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);

  const [tableContent, setTableContent] = useState("");
  useEffect(() => {
    fetch("mdFiles/boat-charter/sailboat-charter-table.md")
      .then((res) => res.text())
      .then((text) => setTableContent(text));
  }, []);

  return (
    <div className="boat-charter-content" id="sailboat-charter">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          {/* left box */}
          <Grid item xs={6}>
            <h1>Sailboat Charter</h1>
            <ReactMarkdown children={content} />
            <Button href="sailboat-day-skipper" size="lg">RYA Day Skipper</Button>
            <Button href="shorebased-marine-radio-short-range" size="lg">RYA Marine Radio Short Range</Button>
          </Grid>
          {/* right box */}
          <Grid item xs={6}>
            <ReactMarkdown remarkPlugins={[gfm]} children={tableContent} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SailBoatCharter;
