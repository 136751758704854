import React from "react";
import NavBarWithScroll from "./Navbar";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header>
      <div className="header-area">
        <Link to="/" className="our-logo">
          <img src="img/psp_logo_V3.png" alt="PSPlogo" />
        </Link>
        <Link
          to="https://www.rya.org.uk/wheres-my-nearest/venue/335357?searchTerm=portugal%20sail%20and%20power"
          className="rya-logo"
        >
          <img src="img/RYA new logo.jpg" alt="RYAlogo" width={100} />
        </Link>
      </div>
      <div className="nav-area">
        <NavBarWithScroll />
      </div>
    </header>
  );
};

export default Header;
