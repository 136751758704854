import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReactMarkdown from "react-markdown";

const Home = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("mdFiles/home.md")
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);

  return (
    <div className="home-content">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={4}>
          {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}> */}
          <Grid item xs>
            <ReactMarkdown children={content} id="home-markdown" />
          </Grid>
        </Grid>
        <Box sx={{ flexGrow: 1, mt: 8, mx: 4 }}>
          <Grid container spacing={4} className="four-square">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* left 2 boxes */}
                <Grid>
                  <a href="/motor">
                    <figure>
                      <img src="img/motorboat.jpg" alt="RYA motor boat" />
                      <figcaption>
                        <h5>Motor</h5>
                      </figcaption>
                    </figure>
                  </a>
                </Grid>
                <Grid>
                  <a href="/power">
                    <figure>
                      <img src="img/powerboat.jpg" alt="RYA power boat" />
                      <figcaption>
                        <h5>Power</h5>
                      </figcaption>
                    </figure>
                  </a>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                {/* right 2 boxes */}
                <Grid>
                  <a href="/sail">
                    <figure>
                      <img src="img/sailboat.jpeg" alt="RYA sail boat" />
                      <figcaption>
                        <h5>Sail</h5>
                      </figcaption>
                    </figure>
                  </a>
                </Grid>
                <Grid>
                  <a href="/shorebased">
                    <figure>
                      <img src="img/theory.jpeg" alt="RYA theory" />
                      <figcaption>
                        <h5>Shorebased</h5>
                      </figcaption>
                    </figure>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <h2>Client Reviews</h2>
            <p>
              <em>
                I learned more in 2 days on the water then 5 years looking at nautical books.
                Dave is a great teacher with a high level of professionalism. Highly recommended!! ~ Serge.
              </em>
            </p>
            <p>
              <em>
                Just wanted to say thank you again for the course. We feel totally confident now and
                our confidence will only improve with practice! We both feel we have learnt a lot and
                the course actually exceeded our expectations
                ~ Simonne & João.
              </em>
            </p>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Home;
